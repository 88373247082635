/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// configre

require('./bootstrap');
require('./extension');

// outside modules

window.moment = require('moment');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min.js')
require('bootstrap-treeview-npm/dist/bootstrap-treeview.min.js')
window.toastr = require('toastr');
require('select2');
require('popper.js/dist/popper.min.js');
require('tempusdominus-bootstrap-4');
require('multiple-select/dist/multiple-select.min');
require('bs-stepper/dist/js/bs-stepper.min.js');
window.YubinBango = require('yubinbango-core2');
require('jquery-ui/ui/widgets/sortable.js');
require('jquery-ui/ui/widgets/resizable.js');
window.holiday = require('holiday-jp');
require('lity/dist/lity.min');
require('lightbox2/dist/js/lightbox-plus-jquery.min.js');
window.FullCalendar = require('fullcalendar-scheduler');


// original modules

require('./modules/funcitons');

window._complete = require('./modules/complete');
window._error = require('./modules/error');
window._ajax = require('./modules/ajax');
window._overlay = require('./modules/overlay');
window._modal = require('./modules/modal');
window._request = require('./modules/request');
window._format = require('./modules/format');
window._camera = require('./modules/camera');

import getFileType from "./modules/real-file-type.js";
window._getFileType = getFileType;

import zdcMap from "./modules/zdc-map.js";
window._zdcMap = zdcMap;

// common sctipt files

require('./common/affiliation');
require('./common/message');
require('./common/overlay');
require('./common/search');
require('./common/select');
require('./common/inputs');
require('./common/dnd');
require('./common/step');
require('./common/file');
require('./common/initialize');
require('./common/button');
require('./common/print_history');

// pages sctipt files

require('./pages/login.index');
require('./pages/setup.index');
require('./pages/dashboard.index');
require('./pages/dashboard_data.index');
require('./pages/contract.index');
require('./pages/holding.index');
require('./pages/company.index');
require('./pages/section.index');
require('./pages/account.index');
require('./pages/group.index');
require('./pages/user.index');
require('./pages/vehicle.index');
require('./pages/it_rollcall_config.index');
require('./pages/remote_rollcall_config.index');
require('./pages/remote_rollcall.index');
require('./pages/rollcall.index');
require('./pages/attendance.index');
require('./pages/diagnosis.index');
require('./pages/inspection.index');
require('./pages/occupation.index');
require('./pages/employment.index');
require('./pages/post.index');
require('./pages/department.index');
require('./pages/scheduled_holiday.index');
require('./pages/holiday_setting.index');
require('./pages/paid_holiday_config.index');
require('./pages/equipment.index');
require('./pages/attendance_type.index');
require('./pages/inspection_type.index');
require('./pages/repair.index');
require('./pages/information.index');
require('./pages/labor.index');
require('./pages/improvement.index');
require('./pages/accident.index');
require('./pages/link.index');
require('./pages/news_release.index');
require('./pages/client_download.index');
require('./pages/blood_pressure.index');
require('./pages/violation.index');
require('./pages/vehicle_classification.index');
require('./pages/vehicle_model.index');
require('./pages/daily_check_item.index');
require('./pages/daily_check.index');
require('./pages/workflow.index');
require('./pages/guidance_kind.index');
require('./pages/guidance.index');
require('./pages/belonging.index');
require('./pages/loading_point.index');
require('./pages/consignor.index');
require('./pages/delivery_item.index');
require('./pages/operation_history.index');
require('./pages/instruction.index');
require('./pages/external_history.index');
require('./pages/allocation.index');
require('./pages/order.index');
require('./pages/request_destination.index');
require('./pages/terminal.index');
require('./pages/rollcall_recording_config.index');
require('./pages/device.index');
require('./pages/shipping_information.index');
require('./pages/drive_judgement.index');
