/**
 * 診断ページ用のスクリプト
 */
if (is_route('rollcall_recording_config.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        rollcall_recording_config_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.rollcall_recording_config_event_set = function()
{
    // 録画設定保存時
    $(document).on('click', '.recording_flag_btn', function (e) {
        const action = $(this).data('action');
        const data = {'company_id': $(this).data('company_id'), 'user_ids':[$(this).data('user_id')], 'recording_flag': $(this).val()};
        _ajax.post(action, data, function (results) {
            toastr.success(results.messages);
            $('#sidebar-usage-count-company-' + results.company_id).html(results.usage_count);
            $('#sidebar-max-count-company-' + results.company_id).html(results.max_count);
            $('#main-area-usage-count').html(results.usage_count);
            $('#main-area-max-count').html(results.max_count);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            load_contents();
        });
    });

    // スマホ録音設定保存時
    $(document).on('click', '.tel_recording_flag_btn', function (e) {
        const action = $(this).data('action');
        const data = {'company_id': $(this).data('company_id'), 'user_ids':[$(this).data('user_id')], 'tel_recording_flag': $(this).val()};
        _ajax.post(action, data, function (results) {
            toastr.success(results.messages);
            $('#sidebar-tel-usage-count-company-' + results.company_id).html(results.usage_count);
            $('#sidebar-tel-max-count-company-' + results.company_id).html(results.max_count);
            $('#main-area-tel-usage-count').html(results.usage_count);
            $('#main-area-tel-max-count').html(results.max_count);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            load_contents();
        });
    });

    // 一括のチェックボックスクリック時
    $(document).on('click', '#rollcall-recording-all-select', function () {
        $(".rollcall-recording-select").prop('checked', $(this).prop('checked'));
    });

    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#rollcall-recording-all-select, .rollcall-recording-select', function () {
        // 一覧のチェック状態を判定し一括設定ボタンを有効化
        const flag = $('.rollcall-recording-select[name="user_id"]:checked').length == 0;
        $('[data-bulk-setting-modal]').prop('disabled', flag);
    });

    // 一括設定の確認モーダル
    $(document).on('click', '[data-bulk-setting-modal]', function () {
        const ids = [];
        $('.rollcall-recording-select[name="user_id"]:checked').each(function() {
            ids.push($(this).val());
        });
        _modal.create_with_data(this, 'bulk-setting-modal', {'company_id':$(this).data('company_id'),'user_ids': ids});
    });

    // 一括設定の適用ボタンクリック時
    $(document).on('click', '[data-bulk-change-modal]', function () {
        _modal.submit(this, 'bulk-change-modal');
    });

    /*---- CSVダウンロード時 ----*/
    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });


}
