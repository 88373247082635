/**
 * 装備品設定ページ用のスクリプト
 */
 import Cookies from "js-cookie";

if (is_route('equipment_classification.equipment_index') || is_route('equipment_classification.index')) {
    // style break point size
    var break_point = 992;

     // コンテンツ読み込み後
     $(document).on('bss:onload', function () {
         equipment_event_set();
     });

     /*---- サイドツリー処理 ----*/

     var tree = {
         element: $('#equipment-tree'),
         selectedNode: [],
         parentNode: [],
         setTree: function() {
             tree.getTree(tree.element.data('action'));
         },
         getTree: function (action) {
             action = extend_url_query(action);
             _ajax.post(action, [], function(results) {
                 /*---- ツリー情報表示 ----*/
                 tree.element.treeview({
                     data: results.data,
                     enableLinks: true,
                     expandIcon: 'fas fa-plus',
                     collapseIcon: 'fas fa-minus',
                     showTags: true,
                     onNodeSelected: function(event, node) {
                         location.href = node.href;
                     },
                     onRendered: function(event, nodes) {
                        // アイテムが11個目以上になるとソートが崩れるため再ソート
                        sortTreeNodes();
                         // expand selected
                         tree.selectedNode = tree.element.treeview('getSelected');
                         tree.element.treeview('expandNode', [ tree.selectedNode, { silent: true } ]);
                         tree.element.treeview('revealNode', [ tree.selectedNode, { silent: true } ]);
                         // set height
                         if($(window).width() <= 992) { // break point
                             $('.ui-resizable-e').hide()
                             $('#side-bar').height('auto');
                         } else {
                             $('.ui-resizable-e').show()
                             $('#side-bar').height($('#contents').height());
                         }
                     }
                 });
             }, function(xhr, status, errorThrown){
                 _error.omitted(xhr, status, errorThrown);
             });
         },
     }

    // treeviewのアイテムを装備品種別名の昇順でソートする
    function sortTreeNodes() {
        let treeContainer = $('#equipment-tree');
        let treeItems = treeContainer.find('li');
        // data-nodeidを3桁目でグループ化したものを格納
        const groups = {};
        // data-nodeidが0.0のグループ（タイトル）
        let zeroGroup = [];

        // data-nodeidの3桁目が同じものをグループ化
        treeItems.each(function() {
            let nodeId = $(this).data('nodeid');
            let nodeText = this.innerText;
            let parts = nodeId.split('.');
            // 0.0の場合先頭に入れるために別で保持する
            if (nodeId === '0.0') {
                zeroGroup.push({ nodeId, nodeText, element: this });
                return;
            }
            // 3桁目が存在する場合にグループ化し、3桁目をグループキーとする（無い場合は空文字）
            let groupKey = parts.length >= 3 ? parts[2] : '';
            if (!groups[groupKey]) {
                groups[groupKey] = [];
            }
            groups[groupKey].push({ nodeId, nodeText, element: this });
        });

        // グループ間でinnerTextの昇順にソート
        let sortedGroups = Object.keys(groups).sort((a, b) => {
            // 各グループの最初の要素のinnerTextで比較
            let groupA = groups[a][0].nodeText;
            let groupB = groups[b][0].nodeText;
            return groupA.localeCompare(groupB);
        });

        // 0.0のグループを最初に持ってくる
        let sortedItems = [];
        // 0.0グループを最初に追加
        if (zeroGroup.length > 0) {
            sortedItems = sortedItems.concat(zeroGroup);
        }

        // その他のグループを順番に追加
        sortedGroups.forEach(groupKey => {
            // 空文字（3桁目がない）のグループはスキップ
            if (groupKey !== '' && groups[groupKey]) {
                groups[groupKey].forEach(item => {
                    // ソートした順番でアイテムを追加
                    sortedItems.push(item.element);
                });
            }
        });

        // ソートされたアイテムをツリーに追加
        treeContainer.append(sortedItems);
    }

    /*---- 車両一括設定の車輛リスト取得 ----*/

    var vehicleSelect = {
        setList: function(elm) {
            // 車輛一覧取得
            let action = elm.data('action');
            console.log(action);
            let data = $('#vehicle-bulk-setting-modal-form').serializeArray();
            var listBlock = elm;

            _ajax.post(action, data, function (results) {
                listBlock.empty();
                listBlock.html(results);
                // all check on/off
                listBlock.find('input[name="vehicle_id_all"]').on('change', function() {
                    if ($(this).prop('checked')) {
                        $('input[name="vehicle_id[]"]').prop('checked', true);
                    } else {
                        $('input[name="vehicle_id[]"]').prop('checked', false);
                    }
                });
                // select function
                listBlock.find('input[name="func_type"]').on('change', function() {
                    if ($(this).val() === '2') {
                        $('.errors').find('span').empty();
                        $('input[name="quentity"]').val('');
                        $('#vehicle-bulk-setting-modal-quantity').val('').prop('disabled', true);
                        $('input[name="price"]').val('').prop('disabled', true);
                        $('#vehicle-bulk-setting-modal-price').val('').prop('disabled', true);
                        $('input[name="note"]').val('').prop('disabled', true);
                    } else {
                        $('input[name="quentity"]').prop('disabled', false);
                        $('#vehicle-bulk-setting-modal-quantity').prop('disabled', false);
                        $('input[name="price"]').prop('disabled', false);
                        $('#vehicle-bulk-setting-modal-price').prop('disabled', false);
                        $('input[name="note"]').prop('disabled', false);
                    }
                    vehicleSelect.setList(listBlock);
                });
                // select section
                listBlock.find('select[name="section_id"]').on('change', function() {
                    vehicleSelect.setList(listBlock);
                });
                // set keyword
                listBlock.find('input[name="keyword"]').on('change', function(){
                    vehicleSelect.setList(listBlock);
                });

            }, function(xhr, status, errorThrown){
                _error.set(listBlock, xhr, status, errorThrown);
            }, 'html');
        }
    }
}

/**
 * ページ読み込み時のイベント設定
 */
window.equipment_event_set = function() {

    /*---- ツリー情報表示 ----*/

    tree.setTree();

    /*---- ツリー&コンテントエリアリサイズ監視 ----*/

    const contentsMargin = 125;
    var sideElement = $('#side-area');
    var contentsElement = $('#contents');
    // 保持幅設定
    // break point
    if($(window).width() <= break_point) {
        contentsElement.width('100%');
        sideElement.width('100%').addClass('sm');
        $('#side-bar').height('auto');
        $('.ui-resizable-e').hide();
    } else {
        if (Cookies.get('equipment_side_area_width', {samesite: 'Lax'}) !== 'undefined') {
            sideElement.width(Cookies.get('equipment_side_area_width', {sameSite: 'Lax'}));
            contentsElement.width((($(window).width() - sideElement.width() - contentsMargin)));
        }
    }
    // サイドエリア幅監視
    sideElement.resizable({
        handles: 'e',
        maxWidth: ($(window).width() * 0.30),
        minWidth: 0,
        resize: function(event, ui) {
            let base = ($(window).width() - contentsMargin) * 0.8 / 80;
            // contentsの幅調整
            if (ui.size.width >= 0) {
                contentsElement.width(base * (100 - (ui.size.width / $(window).width() * 100)));
            }
        },
        stop: function(event, ui) {
            // 幅保持
            Cookies.set('equipment_side_area_width', ui.size.width, {sameSite: 'Lax'});
        }
    });
    // WINDOW幅監視
    $(window).resize(function () {
        // break point
        if($(window).width() <= break_point) {
            contentsElement.width('100%').css('min-width', 'unset');
            sideElement.width('100%').addClass('sm').css('max-width', 'unset');
            $('#side-bar').height('auto');
            $('.ui-resizable-e').hide()
        } else {
            if (sideElement.hasClass('sm')) {
                sideElement.width($(window).width() * 0.30).removeClass('sm');
                $('#side-bar').height($('#contents').height());
                $('.ui-resizable-e').show()
            }
            sideElement.css('max-width', ($(window).width() * 0.40) + 'px')
            contentsElement.width((($(window).width() - sideElement.width() - contentsMargin))).css('min-width', ($(window).width() * 0.58) + 'px');
        }
    })

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id', 'equipment_classification_id', 'parent_id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(){
            tree.setTree();
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(){
            tree.setTree();
        });
    });

    /*---- 車両一括設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-vehicle-bulk-setting-modal]', function () {
        _modal.create(this, 'vehicle-bulk-setting-modal', ['id'], false, function(){
            vehicleSelect.setList($('#vehicle-list'));
        });
    });

    /*---- 車両一括設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-vehicle-bulk-save-modal]', function () {
        _modal.submit(this, 'vehicle-bulk-save-modal');
    });
}
