/**
 * 雇用形態設定ページ用のスクリプト
 */
 if (is_route('scheduled_holiday.holiday_setting_index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        holiday_setting_event_set();
        holiday_setting_setting_event_set();
        holiday_setting_apply_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.holiday_setting_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id'], true, function() {
            holiday_setting_set_valid_weekday();
        });
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal');
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-apply-modal]:not([disabled=disabled])', function () {
        _modal.create(this, 'setting-apply-modal', null, true, function() {
            holiday_setting_apply_user_form_set();
        });
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-apply-modal]', function () {
        _modal.submit(this, 'apply-modal');
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

}
/**
 * 設定モーダル表示時のイベント設定
 */
window.holiday_setting_setting_event_set = function() {

    // 期間開始変更時
    $(document).on('change.datetimepicker', '#holiday_setting-setting-modal-start_date, #holiday_setting-setting-modal-end_date', function () {
        holiday_setting_set_holidays();
    });

    // 法定/所定休日更時
    $(document).on('change', '#holiday_setting-setting-modal .legal_holidays-input, #holiday_setting-setting-modal .prescribed_holidays-input', function () {
        holiday_setting_set_valid_weekday();
        holiday_setting_set_holidays();
    });

    // 祝日変更時
    $(document).on('change', '#holiday_setting-setting-modal-include_national_holiday', function () {
        holiday_setting_set_holidays();
    });

}
/**
 * 法定・所定の選択可能な値を変更する
 */
window.holiday_setting_set_valid_weekday = function() {
    let legalHolidays = [];
    $('#holiday_setting-setting-modal .legal_holidays-input:checked').each(function() {
        legalHolidays.push($(this).val());
    });
    $('#holiday_setting-setting-modal .prescribed_holidays-input').each(function() {
        if (legalHolidays.indexOf($(this).val()) < 0) {
            $(this).removeAttr('disabled');
        } else {
            $(this).prop('checked', false);
            $(this).attr('disabled', 'disabled');
        }
    });
}
/**
 * 休日日数を算出する
 */
window.holiday_setting_set_holidays = function() {
    const strStartDate = $('#holiday_setting-setting-modal-start_date').val();
    const strEndDate = $('#holiday_setting-setting-modal-end_date').val();
    let holidayWeeks = [];
    $('#holiday_setting-setting-modal .legal_holidays-input:checked').each(function() {
        holidayWeeks.push($(this).val());
    });
    $('#holiday_setting-setting-modal .prescribed_holidays-input:checked').each(function() {
        holidayWeeks.push($(this).val());
    });
    const includeHoliday = $('#holiday_setting-setting-modal-include_national_holiday').prop('checked');

    var dates = [];
    $('#holiday_setting-setting-modal-holidays').empty();

    if (strStartDate.length > 0 || strEndDate.length > 0) {
        const startDate = moment(strStartDate, 'YYYY/MM/DD');
        const endDate = moment(strEndDate, 'YYYY/MM/DD');
        let counter = startDate.clone();
        while(counter.isBefore(endDate.clone().add(1, 'days'))) {
            if (holidayWeeks.indexOf(String(counter.day())) >= 0) {
                dates.push(counter.format('YYYY-MM-DD'));
            }
            counter = counter.add(1, 'days');
        }
        if (includeHoliday) {
            $.each(holiday.between(new Date(startDate.format('YYYY-MM-DD')), new Date(endDate.format('YYYY-MM-DD'))), function(index, holiday) {
                day = moment(holiday['date']).format('YYYY-MM-DD');
                if (dates.indexOf(day) < 0) {
                    dates.push(day);
                }
            });
        }
        
    }

    $.each(dates, function(index, date) {
        $('#holiday_setting-setting-modal-holidays').append($('<input>', {
            'name': 'holidays[]',
            'value': date
        }));
    });
    $('#holiday_setting-setting-modal-count').val(dates.length + $('#holiday_setting-setting-modal-count').data('suffix'));

}
/**
 * 一括設定モーダル表示時のイベント設定
 */
window.holiday_setting_apply_event_set = function() {

    // 会社選択時時
    $(document).on('change', '#holiday_setting-apply-modal-company_id,#holiday_setting-apply-modal-section_id,#holiday_setting-apply-modal-id, #holiday_setting-apply-modal-occupation_id', function () {
        holiday_setting_apply_user_form_set();
    });
    
}
/**
 * 適用/非適用者の設定フォームを更新する
 */
window.holiday_setting_apply_user_form_set = function() {
    // 設定フォームを初期化
    $('#holiday_setting-apply-modal-apply-users').empty();
    $('#holiday_setting-apply-modal-apply-users').toggleClass('display-none', true);
    // フォーム情報取得用パラメータ
    const action = $('#holiday_setting-apply-modal-apply-users').data('action');
    const data = {
        'holding_id': $('#holiday_setting-apply-modal-holding_id').val(),
        'company_id': $('#holiday_setting-apply-modal-company_id').val(),
        'section_id': $('#holiday_setting-apply-modal-section_id').val(),
        'occupation_id': $('#holiday_setting-apply-modal-occupation_id').val(),
        'id': $('#holiday_setting-apply-modal-id').val(),
    };
    // 送信結果を取得
    _ajax.post(action, data, function(results) {
        // 設定フォームをセット
        $('#holiday_setting-apply-modal-apply-users').html(results);
        $('#holiday_setting-apply-modal-apply-users').toggleClass('display-none', false);
        initialize_multiselect();
    }, null, 'html');
    
}
