/**
 * 出退勤ページ用のスクリプト
 */
if (is_route('attendance.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        attendance_event_set();
        attendance_setting_event_set();
        attendance_schedule_setting_event_set();
        attendance_day_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.attendance_event_set = function () {

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function() {
        _modal.create(this, 'detail-modal', ['user_id'], true);
    });
    
    // 出勤簿：１日分の一括印刷時
    $(document).on('click', '[data-day-print]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('day-print'));
        // 検索フォームを配列化
        let datas = $('#attendance-day-modal-form').serializeArray();
        
        datas.push({'name':'date', 'value':$(this).data('date')});

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-config-modal]', function () {
        _modal.create(this, 'config-modal', ['company_id', 'section_id'], true, function(){
            attendance_config_event_set();
        });
    });

    /*---- 出勤簿設定保存時 ----*/

    const updatePrintColumns = () => {
        let print_columns_value = '';
        let first = true;

        //有効になっているボタンのdata-column_idをカンマ区切りでhidden要素に設定
        $('#print-column-chip-container').find('.print-column-chip').each((i, val) => {
            if($(val).hasClass('btn-primary')) {
                if(!first)
                    print_columns_value += ',';
                print_columns_value += $(val).data('column_id');
                first = false;
            }
        });
        if(print_columns_value.length == 0)
            print_columns_value = '-';
        $('[name="print_columns"]').val(print_columns_value);
    }

    // 列ボタン押下時
    $(document).on('click', '.print-column-chip', function () {
        $(this).toggleClass('btn-primary');
        $(this).toggleClass('btn-disable');
        updatePrintColumns();
    });

    // 出勤簿設定保存時
    $(document).on('click', '[data-config-save-modal]', function () {
        updatePrintColumns();
        _modal.submit(this, 'config-save-modal', function(results) {
            $('.calendar [data-date=' + results.date + ']').trigger('click');
        });
    });

    /*---- CSVの出力時 ----*/

    // CSVの出力時
    $(document).on('click', '[data-csv-type]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // データを追加
        datas.push({'name' : 'csv_type', 'value' : $(this).data('csv-type')});
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function (e) {
        let value = $(this).data('setting-modal');
        if (value === 'attendance-print-select-modal') {
             // バブリング停止（詳細モーダルを開かせない）
            e.stopPropagation();
        }
        _modal.create(this, 'setting-modal', ['id', 'user_id', 'date'], false, function() {
            if ($('.holiday-status-flag:checked').length) {
                attendance_absence_flag_event($('.holiday-status-flag:checked'));
            }
        });
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        const formId = create_id($(this).data('save-modal') + '-form');
        // 日時が送信されないと休暇系の算出が行えないためdisabledを解除する
        $.each($(formId).find('[name=work_started_at]:disabled,[name=work_ended_at]:disabled,[name=rest_started_at]:disabled,[name=rest_ended_at]:disabled'), function() {
            $(this).prop('disabled', false);
        });
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=attendance-record-modal][data-user_id=' + results.attendance.user_id + ']').trigger('click');
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除処理 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#attendance-setting-modal');
            _modal.hide('#attendance-record-modal');
            $('[data-detail-modal=attendance-record-modal][data-user_id=' + results.attendance.user_id + ']').trigger('click');
        });
    });

    /*---- 一括選択チェックボックス操作時 ----*/

    // 一括のチェックボックスクリック時
    $(document).on('click', '#user-all-select', function () {
        $(".user-schedule").prop('checked', $(this).prop('checked'));
    });
    // 一覧のチェックボックスクリック時
    $(document).on('click', '.user-schedule', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });
    // 一括・一覧のチェックボックス変更時
    $(document).on('change', '#user-all-select, .user-schedule', function () {
        // 一覧のチェック状態を判定し削除ボタンを有効化
        $('#attendance-multi-schedule-button').prop('disabled', ($('.user-schedule:checked').length == 0));
        $('#attendance-multi-schedule-button').toggleClass('display-none', ($('.user-schedule:checked').length == 0));
    });

    /*---- 出勤予定時 ----*/

    const addCheckedUserIdsToArray = (array) => {
        $('.user-schedule[name="user_ids[]"]:checked').map(function() {
            array.push($(this).val());
        });
        return array;
    }

    // 出勤予定ボタンクリック時
    $(document).on('click', '[data-schedule-modal]', function (e) {
        // バブリング停止
        e.stopPropagation();
        var ids = ($(this).data('user_id') == null ? [] : [$(this).data('user_id')]);
        if ($(this).data('user_id') == null) {
            ids = addCheckedUserIdsToArray(ids);
        }
        if (ids.length > 0) {
            _modal.create_with_data(this, 'schedule-modal', {
                'month': $('input[name=month]').val(),
                'user_ids': ids
            }, false, function() {
                // オプションの選択肢が１件のみの場合
                if ($('#attendance-schedule-modal-user_id option:not([value=""])').length == 1) {
                    $('#schedule-not-found').toggleClass('display-none', true);
                    $('#attendance-schedule-modal-user_id').attr('disabled', 'disabled');
                    let userId = $('#attendance-schedule-modal-user_id option:last').val();
                    $('#attendance-schedule-modal-form').append($('<input></input>', {
                        'type': 'hidden',
                        'name': 'user_id',
                        'value': userId,
                    }))
                    $('#attendance-schedule-modal-user_id').val(userId).trigger('change');
                }
            });
        }
    });

    /*---- 出勤予定の社員選択時 ----*/

    // 出勤予定の社員選択時
    $(document).on('change', '#attendance-schedule-modal-user_id', function () {
        $('#schedule-list').empty();
        if ($(this).val().length == 0) {
            $('#schedule-not-found').toggleClass('display-none', false);
            $('#weekday-setup-form').toggleClass('display-none', true);
            return;
        }
        let datas = [];
        datas.push({'name' : 'user_id', 'value' : $(this).val()});
        datas.push({'name' : 'month', 'value' : $('#attendance-schedule-modal-month').val()});
        // 送信
        _ajax.post($(this).data('action'), datas, function (results) {
            $('#schedule-not-found').toggleClass('display-none', true);
            $('#weekday-setup-form').toggleClass('display-none', false);
            $('#schedule-list').html(results);
            initialize('datepicker');
            initialize('tooltip');
            $('#attendance-schedule-modal-daily-schedule .attendance-schedule-daily-item').each(function() {
                attendance_schedule_recalculation(this);
            });
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        }, 'html');
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-schedule-save-modal]', function () {
        _modal.submit(this, 'schedule-save-modal');
    });

    /*---- 休日移行時 ----*/

    // 設定表示時
    $(document).on('click', '[data-transfer-modal]', function () {
        _modal.create(this, 'transfer-modal', ['holding_id', 'company_id', 'section_id', 'user_id', 'id'], true);
    });

    // 設定保存時
    $(document).on('click', '[data-transfer-save-modal]', function () {
        _modal.submit(this, 'transfer-save-modal');
    });

    /*---- チャート表示処理 ----*/

    // チャート表示
    $(document).on('click', '[data-chart-modal]', function () {
        // モーダルのID取得とキャンバスIDを生成
        var modalId = $(this).data('chart-modal');
        var canvasId = $(this).data('canvas-id');
        var action = $(this).data('action');
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        datas.push({'name' : 'user_id', 'value' : $(this).data('user_id')});
        // チャート表示
        attendanve_chart_event_set(action, modalId, canvasId, datas);
    });

    
    /*---- 日別リスト表示時 ----*/

    // 日別リスト表示時
    $(document).on('click', '[data-day-modal]', function () {
        _modal.create(this, 'day-modal', ['date'], true);
    });

    // 表示設定の適用時
    $(document).on('click', '.attendance-day-sort-column', function(e) {
        let form = $('#attendance-day-modal-form');
        let action = $(this).data('action');
        let data = form.serializeArray();
        data.push({'name' : 'order', 'value' : $(this).data('order')}); //column name
        data.push({'name' : 'sort', 'value' : $(this).data('sort')}); //asc or desc
        let modalId = form.data('modal_id');
        _modal.update(modalId, action, data, true);
    });

    // マウスオーバーorアウト時
    $(document).on('mouseenter mouseout', '.attendance-day-sort-column', function(e) {
        let disp = $(e.currentTarget).find('.sort-disp');
        let noDisp = $(e.currentTarget).find('.sort-no-disp');
        disp.removeClass('sort-disp');
        disp.addClass('sort-no-disp');
        noDisp.addClass('sort-disp');
        noDisp.removeClass('sort-no-disp');
    });

    /*---- 選択印刷表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-print-select-modal]', function (e) {
        if ($(e.target).hasClass('file-download')) {
            return;
        }
        // バブリング停止
        e.stopPropagation();
    });

    // 選択印刷
    $(document).on('click', '[data-select-print-modal]', function (e) {
        // バブリング停止
        e.stopPropagation();
        let modalId = create_id($(this).data('select-print-modal'));
        let form = $(modalId).find('form');
        let formId = '#' + form.attr('id');
        // 送信値を配列化
        let datas = form.serializeArray();
        let action = extend_url_query(form.attr('action'));

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.attendance_setting_event_set = function () {

    /*---- 出退勤・休憩時刻のdatetimepicker押下時（カレンダーを最前面に表示する為に表示用親要素を移動） ----*/

    $(document).on('click', '#attendance-setting-modal .datetime-picker', function () {
        let position = $(this).closest('.event-inputs').position().top;
        let marginTop = -(162 - position);
        $(this).closest('.event-input-form-wrapper').find('.datetimepicker_widget_parent').css('margin-top', marginTop + 'px');
    });

    /*---- 出退勤・休憩時刻の追加時 ----*/

    $(document).on('click', '#attendance-setting-modal-add-work-event,#attendance-setting-modal-add-rest-event', function() {
        const eventInput = $(this).parents('.attendance-event-inputs');
        const copy = $(eventInput).find('.event-inputs.copy').clone().removeClass('copy');
        $(eventInput).find('.event-inputs.copy').before($(copy));
        initialize('datepicker');
    });

    /*---- 出勤時間の変更時 ----*/

    $(document).on('change', '.work_started_at', function() {
        if (!is_scheduled()) {
            return;
        }
        if ($(this).val().length == 0) {
            $('#attendance-setting-modal-lateness_flag').prop('checked', false);
            return;
        }
        let workStartedAt = moment($(this).val(), 'YYYY/MM/DD HH:mm');
        let scheduleStartedAt = moment($('#attendance-setting-modal-scheduled_started_at').val(), 'YYYY/MM/DD HH:mm');
        let scheduleEndedAt = moment($('#attendance-setting-modal-scheduled_ended_at').val(), 'YYYY/MM/DD HH:mm');
        $('#attendance-setting-modal-lateness_flag').prop('checked', (workStartedAt.unix() > scheduleStartedAt.unix() && workStartedAt.unix() < scheduleEndedAt.unix()));
    });

    /*---- 退勤時間の変更時 ----*/

    $(document).on('change', '.work_ended_at', function() {
        if ($(this).val().length == 0) {
            $('#attendance-setting-modal-leave_early_flag').prop('checked', false);
            return;
        }
        let workEndedAt = moment($(this).val(), 'YYYY/MM/DD HH:mm');
        if ($('#attendance-setting-modal-scheduled_started_at').length > 0 && $('#attendance-setting-modal-scheduled_ended_at').length > 0) {
            let scheduleStartedAt = moment($('#attendance-setting-modal-scheduled_started_at').val(), 'YYYY/MM/DD HH:mm');
            let scheduleEndedAt = moment($('#attendance-setting-modal-scheduled_ended_at').val(), 'YYYY/MM/DD HH:mm');
            $('#attendance-setting-modal-leave_early_flag').prop('checked', (workEndedAt.unix() > scheduleStartedAt.unix() && workEndedAt.unix() < scheduleEndedAt.unix()));
        }
    });

    /*---- 有給休暇・慶弔・代休の選択時 ----*/

    $(document).on('change', '.holiday-status-flag', function() {
        attendance_absence_flag_event(this);
    });

    /*---- 休憩開始時刻設定時 ----*/

    // 休憩開始時刻設定時
    $(document).on('change', '.attendance-rest-events .event-inputs:not(.copy) .rest_started_at', function () {
        set_same_time_interval(this, $(this).parents('.event-inputs').find('.rest_ended_at'));
    });

    /*---- 出勤区分変更時 ----*/

    $(document).on('change', '#attendance-setting-modal-type', function() {
        let option = $(this).children(':selected');
        let date = $('#attendance-setting-modal-date').val();
        $('.work_started_at:not(.copy .work_started_at)').val(time_to_datetime($(option).data('work-started-time'), date));
        $('.work_ended_at:not(.copy .work_ended_at)').val(time_to_datetime($(option).data('work-ended-time'), date));
        $('.rest_started_at:not(.copy .rest_started_at)').val(time_to_datetime($(option).data('rest-started-time'), date));
        $('.rest_ended_at:not(.copy .rest_ended_at)').val(time_to_datetime($(option).data('rest-ended-time'), date));
    });
}
/**
 * 休暇系ステータスの操作時のイベント設定
 * 
 * @param {*} elm 
 */
window.attendance_absence_flag_event = function(elm) {
    let id = $(elm).attr('id');
    let checked = $(elm).prop('checked');
    $('.attendance-status-flag').each(function() {
        if ($(this).attr('id') != id) {
            if (checked) {
                $(this).prop('checked', false);
            }
            $(this).attr('disabled', checked);
        }
    });
    $('.work_started_at').attr('disabled', checked);
    $('.work_ended_at').attr('disabled', checked);
    $('.rest_started_at').attr('disabled', checked);
    $('.rest_ended_at').attr('disabled', checked);
    $('.add-event').attr('disabled', checked);
    $('.delete-event').attr('disabled', checked);
    // 解除時は出勤・退勤時間の変更イベントを実行する
    if (!checked) {
        $('.work_started_at').trigger('change');
        $('.work_ended_at').trigger('change');
    }
}
/**
 * 出勤予定設定モーダル表示時のイベント設定
 */
window.attendance_schedule_setting_event_set = function () {

    /*---- 一括適用の出勤区分変更時 ----*/

    $(document).on('change', '#attendance-schedule-modal-weekday_schedule_type', function() {
        let option = $(this).children(':selected');
        $('#attendance-schedule-modal-weekday_scheduled_started_at').val($(option).data('work-started-time'));
        $('#attendance-schedule-modal-weekday_scheduled_ended_at').val($(option).data('work-ended-time'));
        $('#attendance-schedule-modal-weekday_scheduled_rest_started_at').val($(option).data('rest-started-time'));
        $('#attendance-schedule-modal-weekday_scheduled_rest_ended_at').val($(option).data('rest-ended-time'));
    });

    /*---- 出勤時間・退勤時間の変更時 ----*/

    $(document).on('change', '.scheduled_started_at, .scheduled_ended_at, .scheduled_rest_started_at, .scheduled_rest_ended_at', function() {
        let tr = $(this).parents('.attendance-schedule-daily-item');
        attendance_schedule_recalculation(tr);
    });

    /*---- 一括設定時 ----*/

    $(document).on('click', '#attendance-schedule-modal-all_weekday_submit', function() {
        let targetType = $('#attendance-schedule-modal-weekday_schedule_target_type').val();
        let workType = $('#attendance-schedule-modal-weekday_schedule_type').val();
        let workStartTime = $('#attendance-schedule-modal-weekday_scheduled_started_at').val();
        let workEndTime = $('#attendance-schedule-modal-weekday_scheduled_ended_at').val();
        let restStartTime = $('#attendance-schedule-modal-weekday_scheduled_rest_started_at').val();
        let restEndTime = $('#attendance-schedule-modal-weekday_scheduled_rest_ended_at').val();
        let selector = '#attendance-schedule-modal-daily-schedule .schedule-date:not(.legal-holiday,.prescribed-holiday)';
        if (targetType != 7) {
            selector = '#attendance-schedule-modal-daily-schedule .week-' + targetType;
        }
        $(selector).each(function() {
            let date = $(this).data('date') + ' ';
            let tr = $(this).parents('tr');
            $(tr).find('.scheduled_type').val(workType);
            $(tr).find('.scheduled_started_at').val(((workStartTime != '') ? time_to_datetime(workStartTime, date) : ''));
            $(tr).find('.scheduled_ended_at').val(((workEndTime != '') ? time_to_datetime(workEndTime, date) : ''));
            $(tr).find('.scheduled_rest_started_at').val(((restStartTime != '') ? time_to_datetime(restStartTime, date) : ''));
            $(tr).find('.scheduled_rest_ended_at').val(((restEndTime != '') ? time_to_datetime(restEndTime, date) : ''));
            attendance_schedule_recalculation(tr);
        });
    });

    /*---- 出勤区分変更時 ----*/

    $(document).on('change', '.scheduled_type', function() {
        let option = $(this).children(':selected');
        let tr = $(this).parents('tr');
        let date = $(tr).children('.schedule-date').data('date');
        $(tr).find('.scheduled_started_at').val(time_to_datetime($(option).data('work-started-time'), date));
        $(tr).find('.scheduled_ended_at').val(time_to_datetime($(option).data('work-ended-time'), date));
        $(tr).find('.scheduled_rest_started_at').val(time_to_datetime($(option).data('rest-started-time'), date));
        $(tr).find('.scheduled_rest_ended_at').val(time_to_datetime($(option).data('rest-ended-time'), date));
        attendance_schedule_recalculation(tr);
    });

    /*---- スケジュールクリア ----*/

    $(document).on('click', '.attendance-schedule-clear', function() {
        let tr = $(this).parents('tr');
        $(tr).find('.scheduled_type').val('');
        $(tr).find('.scheduled_started_at').val('');
        $(tr).find('.scheduled_ended_at').val('');
        $(tr).find('.scheduled_rest_started_at').val('');
        $(tr).find('.scheduled_rest_ended_at').val('');
        attendance_schedule_recalculation(tr);
    });

}
/**
 * スケジュール済みのレコードかを判定する
 *
 * @returns
 */
window.is_scheduled = function() {
    return (
        $('#attendance-setting-modal-scheduled_started_at').length > 0 &&
        $('#attendance-setting-modal-scheduled_started_at').val().length > 0 &&
        $('#attendance-setting-modal-scheduled_ended_at').length > 0 &&
        $('#attendance-setting-modal-scheduled_ended_at').val().length > 0
    );
}
/**
 * 行内の各時間の再計算を行う
 */
window.attendance_schedule_recalculation = function (tr) {
    // 値が設定されている場合はmoment化、未指定時はnull
    let workStart = (!$(tr).find('.scheduled_started_at').val() ? null : moment($(tr).find('.scheduled_started_at').val(), 'YYYY/MM/DD HH:mm'));
    let workEnd = (!$(tr).find('.scheduled_ended_at').val() ? null : moment($(tr).find('.scheduled_ended_at').val(), 'YYYY/MM/DD HH:mm'));
    let restStart = (!$(tr).find('.scheduled_rest_started_at').val() ? null : moment($(tr).find('.scheduled_rest_started_at').val(), 'YYYY/MM/DD HH:mm'));
    let restEnd = (!$(tr).find('.scheduled_rest_ended_at').val() ? null : moment($(tr).find('.scheduled_rest_ended_at').val(), 'YYYY/MM/DD HH:mm'));
    // 各時間を算出
    let results = attendance_schedule_time_calculation(workStart, workEnd, restStart, restEnd);
    // 休憩時間が算出されている場合は値を指定
    $(tr).find('.scheduled_rest_minutes').val(((results.rest_minutes != null) ? minutes_to_time(results.rest_minutes) : ''));
    // 時間外が算出されている場合は値を指定
    $(tr).find('.scheduled_over_work_minutes').val(((results.over_work_minutes != null) ? minutes_to_time(results.over_work_minutes) : ''));
    // 夜間作業時間が算出されている場合は値を指定
    $(tr).find('.scheduled_night_work_minutes').val(((results.night_work_minutes != null) ? minutes_to_time(results.night_work_minutes) : ''));
}
/**
 * 出退勤予定に含まれる各種時間を算出する
 *
 * @param {*} workStart
 * @param {*} workEnd
 * @param {*} restStart
 * @param {*} restEnd
 * @returns
 */
window.attendance_schedule_time_calculation = function(workStart, workEnd, restStart, restEnd) {
    // 作業時間
    let workMinutes = null;
    let overWorkMinutes = null;
    let overWorkLimitMinutes = 8 * 60;
    let nightRestraintMinutes = null;
    let nightWorkMinutes = null;
    let allRestMinutes = null;
    let restMinutes = null;
    let nightRestMinutes = null;
    // 業務の開始・終了が設定されている場合
    if (workStart != null && workEnd != null) {
        // 休憩の開始・終了が設定されている場合
        if (restStart != null && restEnd != null) {
            // 休憩開始・終了が業務時間内
            if (workStart.unix() <= restStart.unix() && workEnd.unix() >= restStart.unix() &&
                workStart.unix() <= restEnd.unix() && workEnd.unix() >= restEnd.unix()) {
                // 作業時間を算出
                workMinutes = restStart.diff(workStart, 'minutes') + workEnd.diff(restEnd, 'minutes');
                allRestMinutes = workEnd.diff(workStart, 'minutes') - workMinutes;
            }
            // 夜間休憩時間（分）の算出
            nightRestMinutes = 0;
            // 1分づつ加算し時間帯が夜間なら値を追加する
            for(i = 0; i < restEnd.diff(restStart, 'minutes'); i ++) {
                current = moment(restStart).add(i, 'minutes');
                if (current.format('H') >= 22 || current.format('H') < 5) {
                    nightRestMinutes ++;
                }
            }
        } else {
            // 作業時間を算出
            workMinutes = workEnd.diff(workStart, 'minutes');
        }
        // 夜間拘束時間（分）の算出
        nightRestraintMinutes = 0;
        // 1分づつ加算し時間帯が夜間なら値を追加する
        for(i = 0; i < workEnd.diff(workStart, 'minutes'); i ++) {
            current = moment(workStart).add(i, 'minutes');
            hour = current.format('H');
            if (hour >= 22 || hour < 5) {
                nightRestraintMinutes ++;
            }
        }
    }
    // 残業時間の算出
    if (workMinutes != null) {
        overWorkMinutes = ((overWorkLimitMinutes < workMinutes) ? workMinutes - overWorkLimitMinutes : 0);
    }
    // 夜間作業時間の算出
    if (nightRestraintMinutes != null) {
        nightWorkMinutes = ((nightRestMinutes == null) ? nightRestraintMinutes : nightRestraintMinutes - nightRestMinutes);
    }
    // 休憩時間
    if (allRestMinutes != null) {
        restMinutes = 0;
        restMinutes = allRestMinutes - ((nightRestMinutes == null) ? 0 : nightRestMinutes);
    }
    return {
        'work_minutes': workMinutes,
        'over_work_minutes': overWorkMinutes,
        'night_restraint_minutes': nightRestraintMinutes,
        'night_work_minutes': nightWorkMinutes,
        'all_rest_minutes': allRestMinutes,
        'rest_minutes': restMinutes,
        'night_rest_minutes': nightRestMinutes,
    };
}
/**
 * チャート表示を行う
 * @param {*} action
 * @param {*} modalId
 * @param {*} canvasId
 * @param {*} datas
 */
window.attendanve_chart_event_set = function(action, modalId, canvasId, datas) {
    // 目盛フォントサイズ
    var ticksFontSize = 14;
    // 送信
    _ajax.post(action, datas, function (results) {
        $(modalId + ' .modal-title').text(results.title);
        $(modalId + ' .chart-period').text(results.period);
        var maxHourSetting = results.maxHourSetting;
        chart(canvasId, 'bar', {
            labels: results.labels,
            datasets: [
            {
                label: results.datasets.work_hours.label,
                //data: results.datasets.work_hours.datas,
                data: results.datasets.inside_work_hours.datas,
                borderColor : "rgba(54,164,235,0.8)",
                backgroundColor : "rgba(54,164,235,0.5)",
                yAxisID: "work-hour-y-axis",
                tooltips: results.datasets.work_hours.datas,
                stack: 'stack-1',
            },
            {
                label: results.datasets.over_work_hours.label,
                data: results.datasets.over_work_hours.datas,
                borderColor : "rgba(235,189,54,0.8)",
                backgroundColor : "rgba(235,189,54,0.5)",
                yAxisID: "work-hour-y-axis",
                tooltips: results.datasets.over_work_hours.datas,
                stack: 'stack-1',
            },
            {
                type: "line",
                fill: false,
                label: results.datasets.total_over_work_hours.label,
                data: results.datasets.total_over_work_hours.datas,
                lineTension: 0,
                borderColor : "rgba(189,189,189,0.8)",
                backgroundColor : "rgba(189,189,189,0.5)",
                yAxisID: "over-work-hour-y-axis",
                tooltips: results.datasets.total_over_work_hours.datas,
            },
            ],
        }, {
            legend: {
                position: "bottom",
                onClick: function () { return false },
                labels: {
                    fontSize: ticksFontSize
                }
            },
            tooltips: {
                displayColors: false,
                mode: 'nearest',
                //intersect: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return;
                    },
                    label: function (tooltipItem, data){
                        return results.tooltips[tooltipItem.index].messages;
                    }
                },
            },
            responsive: true,
            scales: {
                yAxes: [{
                    id: "work-hour-y-axis",
                    type: "linear",
                    position: "left",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.work_hours.ticks[results.datasets.work_hours.ticks.length - 1],
                        max: results.datasets.work_hours.ticks[0],
                        fontSize: ticksFontSize
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.work_hours.ticks;
                        return;
                    }
                }, {
                    id: "over-work-hour-y-axis",
                    type: "linear",
                    position: "right",
                    ticks: {
                        autoSkip: false,
                        min: results.datasets.total_over_work_hours.ticks[results.datasets.total_over_work_hours.ticks.length - 1],
                        max: results.datasets.total_over_work_hours.ticks[0],
                        fontSize: ticksFontSize
                    },
                    afterBuildTicks: function(scale) {
                        scale.ticks = results.datasets.total_over_work_hours.ticks;
                        return;
                    },
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontSize: ticksFontSize
                    },
                }],
            },
            //基準線を引く
            annotation: {
                annotations: [{
                    type: 'line',
                    id: 'hLine',
                    mode: 'horizontal',
                    scaleID: 'over-work-hour-y-axis',
                    value: maxHourSetting,
                    borderWidth: 2,
                    borderColor: 'red',
                    label: {
                    position: 'right',
                    yAdjust:10,
                    content: maxHourSetting,
                    enabled: true
                    }
                }]
            },
        });
        // モーダルを閉じる
        _modal.show(modalId);
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}
/**
 * 出勤簿設定時イベントセット
 */
window.attendance_config_event_set = function ()
{
    // 初期化
    $('.closing_day_type').each(function() {
        var closing_day = $('input#attendance-config-modal-closing_day_' + $(this).data('section_id') + '_' + $(this).data('employment_id'));
        // disabled制御
        if ($(this).val() === '99') {
            closing_day.prop('disabled', false).parents('.form-group').find('.badge-required').show();
        } else {
            closing_day.prop('disabled', true).parents('.form-group').find('.badge-required').hide();
        }
    });
    // クリックによるdisabled制御
    $(document).on('change', '.closing_day_type', function () {
        var closing_day = $('input#attendance-config-modal-closing_day_' + $(this).data('section_id') + '_' + $(this).data('employment_id'));
        var closing_day_value = $("input[name=\'closing_day[" + $(this).data('section_id') + "][" + $(this).data('employment_id') + "]\'");
        var old = closing_day.data('old');
        if ($(this).val() === '99') {
            closing_day.val(old).prop('disabled', false).parents('.form-group').find('.badge-required').show();
            closing_day_value.val(old);
        } else {
            closing_day.val('').prop('disabled', true).parents('.form-group').find('.badge-required').hide();
            closing_day_value.val('');
        }
    });
    // 改善基準連動のスイッチの切り替え時の表示制御
    $(document).on('change', '.operation_data_linkage_flag', function () {
        const disabled = $(this).val() != true;
        $('.operation_linkage_area_' + $(this).data('section_id') + '_' + $(this).data('employment_id')).toggleClass('display-none', disabled);
    });
    // 改善基準による出退勤登録を優先のスイッチの切り替え時の表示制御
    $(document).on('change', '.operation_data_override_flag', function () {
        const disabled = $(this).val() == true;
        const start = $('input#attendance-config-modal-work_start_addition_minutes_' + $(this).data('section_id') + '_' + $(this).data('employment_id'));
        $(start).attr('disabled', disabled);
        const end = $('input#attendance-config-modal-work_end_addition_minutes_' + $(this).data('section_id') + '_' + $(this).data('employment_id'));
        $(end).attr('disabled', disabled);
    });

}

/**
 * 日モーダル表示時のイベント設定
 */
window.attendance_day_event_set = function () {

    $(document).on('select2:selecting', '#attendance-day-modal-holding_id:enabled, #attendance-day-modal-company_id:enabled, #attendance-day-modal-section_id', function(e) {
        $(this).val(e.params.args.data.id);
        if ($(this).attr('id') == 'attendance-day-modal-holding_id') {
            $('#attendance-day-modal-company_id').val('');
            $('#attendance-day-modal-section_id').val('');
        }
        if ($(this).attr('id') == 'attendance-day-modal-company_id') {
            $('#attendance-day-modal-section_id').val('');
        }
        let form = $(this).parents('form');
        let data = $(form).serializeArray();
        let action = $(form).attr('action');
        let modalId = $(form).data('modal_id');
        _modal.update(modalId, action, data, true);
    });
}